import styled from "styled-components";
import { motion } from "framer-motion";
import Img from "next/legacy/image";
import * as breakpoints from "../abstracts/_breakpoints";
import * as colors from "../abstracts/_variables.styles";
import * as typo from "../abstracts/_typography.styles";

const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const OnePanelCarouselContainer = styled(motion.div)`
  grid-area: carousel;
  background: inherit;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  margin: 0rem 0rem;
  @media ${breakpoints.xxSmallDevices} {
  }
  @media ${breakpoints.extraSmallDevices} {
  }
  @media ${breakpoints.mediumDevices} {
  }
  @media ${breakpoints.mediumDevicesLandscape} {
  }
  @media ${breakpoints.largeDevices} {
  }
  @media ${breakpoints.extraLargeDevices} {
  }
  @media ${breakpoints.xxLargeDevices} {
  }
`;

export const TwoColumnGrid = styled(motion.div)`
  z-index: 1;
  display: grid;
  position: relative;
  height: 100%;
  background: inherit;
  margin: 0rem 0rem;
  grid-template-columns: 100%;
  grid-template-rows: ${(props) =>
    props.content == "textFirst"
      ? "auto auto"
      : props.content == "imageFirst"
        ? "auto auto"
        : "auto auto"};
  height: ${(props) =>
    props.size === "small"
      ? "90vh"
      : props.size === "large"
        ? "100vh"
        : "auto"};
  grid-template-areas:
    "right"
    "left";
  @media ${xxSmallDevices} {
    grid-template-rows: ${(props) =>
      props.content == "textFirst"
        ? "auto auto"
        : props.content == "imageFirst"
          ? "auto auto"
          : "auto auto"};
    grid-template-areas:
      "right"
      "left";
    padding: 2rem 2rem;
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
    padding: 1rem 2rem;
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    grid-template-rows: ${(props) =>
      props.content == "textFirst"
        ? "auto auto"
        : props.content == "imageFirst"
          ? "auto auto"
          : "auto auto"};
    grid-template-areas:
      "left"
      "right";
    padding: 1rem 0rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    margin: 0rem 0rem 0rem 0rem;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "left right";
  }
  @media ${extraLargeDevices} {
    margin: 0rem 0rem 0rem 0rem;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "left right";
  }
  @media ${xxLargeDevices} {
    margin: 0rem 0rem 0rem 0rem;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "left right";
  }
`;

export const GoodDayGrid = styled(motion.div)`
  display: grid;
  position: relative;
  height: auto;
  background: inherit;
  margin: 0rem 0rem;
  grid-template-rows: 45vh auto;
  grid-template-columns: 100%;
  grid-template-areas:
    "left"
    "right";
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }

  @media ${mediumDevices} {
    grid-template-rows: 45vh auto;
    padding: 2rem 0rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    grid-template-rows: 45vh auto;
    padding: 2rem 0rem;
  }
  @media ${extraLargeDevices} {
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 0rem;
    grid-template-columns: 65% 35%;
    grid-template-rows: 90vh;
    grid-template-areas: "left right";
  }
  @media ${xxLargeDevices} {
    margin: 0rem 0rem 0rem 0rem;
    grid-template-columns: 65% 35%;
    grid-template-rows: 90vh;
    grid-template-areas: "left right";
  }
`;

export const GridItemPadding = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${(props) =>
    props.bg === "primary"
      ? colors.warmPalette.primary
      : props.bg === "secondary"
        ? colors.warmPalette.secondary
        : props.bg === "tertiary"
          ? colors.warmPalette.tertiary
          : "inherit"};
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    padding: 0rem 8rem;
  }
  @media ${largeDevices} {
    padding: 2rem;
  }
  @media ${extraLargeDevices} {
    padding: 1rem;
  }
  @media ${xxLargeDevices} {
    padding: 1rem;
  }
`;

export const GridItem = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${(props) =>
    props.bg === "primary"
      ? colors.warmPalette.primary
      : props.bg === "secondary"
        ? colors.warmPalette.secondary
        : props.bg === "tertiary"
          ? colors.warmPalette.tertiary
          : "inherit"};
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    padding: 0rem 4rem;
  }
  @media ${largeDevices} {
    padding: ${({ position }) =>
      position === "left"
        ? "2rem 2rem 2rem 10rem"
        : position === "right"
          ? "2rem 10rem 2rem 2rem"
          : "2rem"};
  }
  @media ${extraLargeDevices} {
    padding: ${({ position }) =>
      position === "left"
        ? "2rem 2rem 2rem 10rem"
        : position === "right"
          ? "2rem 10rem 2rem 2rem"
          : "0rem"};
  }
  @media ${xxLargeDevices} {
    padding: ${({ position }) =>
      position === "left"
        ? "2rem 2rem 6rem 12rem"
        : position === "right"
          ? "2rem 12rem 6rem 2rem"
          : "0rem"};
  }
`;

export const GridItemSideChanges = styled(motion.div)`
  grid-area: ${(props) => (props.side === "right" ? "right" : "left")};
  background: ${(props) =>
    props.background === "odd" ? colors.warmPalette.tertiary : "white"};
  height: auto;
  width: 100%;
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const ThreePanelCarouselContainer = styled.div`
  grid-area: carousel;
  background: ${colors.warmPalette.neutralLight};
  height: 100%;
  width: 100%;
  @media ${breakpoints.xxSmallDevices} {
    height: 100%;
    width: 100%;
  }
  @media ${breakpoints.xxSmallDevicesLandscape} {
    height: 100%;
    width: 100%;
    ${"" /* border: 0.5rem solid purple; */}
  }
  @media ${breakpoints.extraSmallDevices} {
    height: 100%;
    width: 100%;
  }
  @media ${breakpoints.extraSmallDevicesLandscape} {
    height: 100%;
    width: 100%;
    ${"" /* border: 0.5rem solid purple; */}
  }
  @media ${breakpoints.mediumDevices} {
    height: 100%;
    width: 100%;
  }
  @media ${breakpoints.mediumDevicesLandscape} {
    height: 100%;
    width: 100%;
  }
  @media ${breakpoints.largeDevices} {
    height: 100%;
    width: 100%;
  }
  @media ${breakpoints.extraLargeDevices} {
  }
  @media ${breakpoints.xxLargeDevices} {
  }
`;

export const ButtonContainer = styled(motion.div)`
  z-index: 100;
  margin: 0rem auto;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const ImgContainerBox = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0rem;
  margin: 2rem 0rem;
  @media ${xxSmallDevices} {
    width: 90%;
    height: 100%;
    overflow: hidden;
    padding: 0rem;
  }
  @media ${xxSmallDevicesLandscape} {
    width: auto;
    height: 100%;
    max-height: 60vh;
    overflow: hidden;
    padding: 2rem;
  }
  @media ${extraSmallDevices} {
    width: 90%;
    height: 70%;
    overflow: hidden;
    padding: 0rem;
  }
  @media ${extraSmallDevicesLandscape} {
    width: auto;
    max-height: 60vh;
    overflow: hidden;
    padding: 0rem 4rem;
  }
  @media ${mediumDevices} {
    width: auto;
    height: 100%;
    max-height: 45vh;
    overflow: hidden;
    padding: 0rem 2rem;
  }
  @media ${mediumDevicesLandscape} {
    width: auto;
    height: 100%;
    overflow: hidden;
    padding: 0rem 4rem;
    max-height: 60vh;
  }
  @media ${largeDevices} {
    width: auto;
    height: 100%;
    max-height: 50vh;
    overflow: hidden;
    padding: 0rem 4rem;
  }
  @media ${extraLargeDevices} {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0rem 0rem;
    padding: 2rem 4rem 2rem 4rem;
  }
  @media ${xxLargeDevices} {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0rem 0rem;
    position: relative;
    z-index: 2;
    ${"" /* left: 10vw; */}
  }
`;

export const Card = styled(motion.div)`
  flex-shrink: 0;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: url(${(props) => props.imgurl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0rem 0rem;
  border-radius: 0rem;
  display: grid;
  grid-template-rows: 20% 60% 20%;
  grid-area: "head" "body" "footer";
  grid-template-columns: 100%;
  box-shadow: 27px 15px 13px 15px rgba(181, 177, 181, 0.73);
  @media ${xxSmallDevices} {
    min-height: 40vh;
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
    width: 44%;
  }

  @media ${mediumDevices} {
    width: 50%;
  }
  @media ${mediumDevicesLandscape} {
    width: 50%;
  }
  @media ${largeDevices} {
    width: 50%;
  }
  @media ${extraLargeDevices} {
    width: 33.33333%;
  }
  @media ${xxLargeDevices} {
    width: 33.33333%;
  }
`;

export const CardHeading = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 0rem 0rem;
  padding: 0rem 0rem;
  margin: 0rem 0rem;
  background: url(${(props) => props.imgurl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }

  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const CardFooter = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 0rem 0rem;
  padding: 0rem auto;
  margin: 0rem 10%;
  grid-row-start: 3;
  display: grid;
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }

  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const OverlayContainer = styled.div`
  ${"" /* border: 1rem solid yellow; */}
  @media ${xxSmallDevices} {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    top: -100%;
    left: 0;
    bottom: 0;
  }
  @media ${xxSmallDevicesLandscape} {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    top: -100%;
    left: 0;
    bottom: 0;
    ${"" /* border: 0.25rem solid red; */}
  }
  @media ${extraSmallDevices} {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    top: -100%;
    left: 0;
    bottom: 0;
  }
  @media ${extraSmallDevicesLandscape} {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    top: -100%;
    left: 0;
    bottom: 0;
    ${"" /* border: 0.25rem solid red; */}
  }

  @media ${mediumDevices} {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    top: -100%;
    left: 0;
    bottom: 0;
  }
  @media ${mediumDevicesLandscape} {
    position: static;
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  @media ${largeDevices} {
    position: static;
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  @media ${extraLargeDevices} {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  @media ${xxLargeDevices} {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
`;

export const Overlay2 = styled(motion.div)`
  position: absolute;
  opacity: 1;
  background-color: ${colors.warmPalette.tertiary};
  height: 20%;
  width: calc(100% - 2rem);
  padding: 0rem;
  margin: 1rem;
  bottom: 0;
  cursor: pointer;
  padding-right: 2rem;
  @media ${xxSmallDevices} {
    opacity: 0.9;
  }
  @media ${xxSmallDevicesLandscape} {
    opacity: 0.9;
  }
  @media ${extraSmallDevices} {
    opacity: 0.9;
  }
  @media ${extraSmallDevicesLandscape} {
    opacity: 0.9;
  }

  @media ${mediumDevices} {
    opacity: 0.7;
  }
  @media ${mediumDevicesLandscape} {
    opacity: 0.7;
  }
  @media ${largeDevices} {
    opacity: 1;
  }
  @media ${extraLargeDevices} {
  }
`;
export const OverlayText = styled(motion.div)`
  color: black;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  @media ${xxSmallDevices} {
    display: none;
    font-size: 1.25rem;
    width: 90%;
    top: 35%;
  }
  @media ${extraSmallDevices} {
    display: none;
    font-size: 1.25rem;
    width: 90%;
    top: 35%;
  }

  @media ${mediumDevices} {
    display: inline-block;
    font-size: 1.25rem;
    top: 40%;
  }
  @media ${mediumDevicesLandscape} {
    display: inline-block;
    font-size: 1.25rem;
    top: 35%;
  }
  @media ${largeDevices} {
    font-size: 1.5rem;
    width: 70%;
    text-align: left;
  }
  @media ${extraLargeDevices} {
    font-size: 1.5rem;
    width: 60%;
    text-align: left;
  }
  @media ${xxLargeDevices} {
  }
`;

export const OverlayButton = styled(motion.div)`
  color: black;
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0rem 0rem;
  opacity: 1;
  @media ${xxSmallDevices} {
    top: 75%;
    left: 50%;
  }
  @media ${extraSmallDevices} {
    top: 75%;
    left: 50%;
  }

  @media ${mediumDevices} {
  }
  @media ${largeDevices} {
    top: 80%;
    left: 50%;
  }
  @media ${extraLargeDevices} {
  }
`;

export const InsetDiv = styled(motion.div)`
  position: relative;
  height: 50vh;
  width: 88vw;
  margin: 5vh 6vw;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
    height: 80vh;
    margin: 10vh 5vw;
    width: 90vw;
  }
  @media ${xxLargeDevices} {
  }
`;

export const InsetDivSmall = styled(motion.div)`
  position: relative;
  height: 50vh;
  width: 80vw;
  margin: 5vh 10vw;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
    height: 80vh;
    margin: 10vh 10vw;
    width: 80vw;
  }
  @media ${xxLargeDevices} {
  }
`;

export const RoomsInsetDivAmenities = styled(motion.div)`
  width: 90vw;
  height: 125vh;
  padding-top: 40vh;
  position: relative;
  top: -40vh;
  margin: 0rem 5vw -30vh 5vw;
  z-index: -1;
  background: ${colors.warmPalette.contrast};
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
    height: 125vh;
    padding-top: 40vh;
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    height: 100vh;
    padding-top: 40vh;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    padding: 35vh 0rem;
    height: 70vh;
  }
  @media ${extraLargeDevices} {
    height: 90vh;
  }
  @media ${xxLargeDevices} {
  }
`;

export const RoomsInsetDiv = styled(motion.div)`
  width: 90vw;
  height: 70vh;
  position: relative;
  top: -40vh;
  margin: 0rem 5vw -30vh 5vw;
  padding: 35vh 0rem;
  z-index: -1;
  background: ${colors.warmPalette.contrast};
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
    height: 90vh;
  }
  @media ${xxLargeDevices} {
  }
`;

export const SectionHeaderContainer = styled(motion.div)`
  margin: 2rem 2rem 2rem 2rem;
  z-index: 100;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    margin: 4rem 4rem 2rem 4rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    margin: 8rem 10rem 2rem 10rem;
  }
  @media ${extraLargeDevices} {
    margin: 6rem 10rem 4rem 10rem;
  }
  @media ${xxLargeDevices} {
    margin: 6rem 12rem 4rem 12rem;
  }
`;

export const LargeBox = styled(motion.div)`
  margin: 2rem 6rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
