import { colors } from "./_allBase.styles";

const headerTypography = {
  whileHover: { letterSpacing: "0.75rem" },
  transition: { type: "spring", stiffness: 200, damping: 40, duration: 0.5 },
};

const logoAnimation = {
  whileHover: { scaleX: 1.1 },
  whileTap: { scale: 0.9 },
  transition: {
    duration: 0.2,
  },
};

const headerScrollVariants = {
  solid: {
    background: `${colors.warmPalette.secondary}`,
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.25,
    },
  },
  hidden: {
    background: "rgba(255,255,255, 0)",
    transition: {
      type: "tween",
      ease: "easeOut",
      duration: 0.25,
    },
  },
};

function headerScrollAnimation(scroll) {
  return {
    initial: "hidden",
    animate: scroll < 30 ? "hidden" : "solid",
    variants: headerScrollVariants,
  };
}

const fadeInVariants = {
  hidden: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.05,
      type: "tween",
      ease: "easeIn",
    },
  },
};

const cursorDirectionAnimation = {
  small: {
    scale: 1,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
  rotate: {
    scale: 1,
    rotate: 180,
    transition: {
      duration: 0,
      type: "tween",
    },
  },
  exit: {
    scale: 0,
    transition: {
      duration: 0.25,
      type: "tween",
    },
  },
};

const slideInX = {
  hidden: {
    opacity: 0,
    x: -300,
    transition: {
      duration: 2,
      type: "tween",
      ease: "easeIn",
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.25,
      duration: 0.5,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const fadeInAndOutVariants = {
  enter: {
    opacity: -1,
    zIndex: 0,
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeInOut",
    },
  },
  center: {
    zIndex: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeInOut",
    },
  },
  exit: {
    zIndex: -1,
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const clipPath = {
  enter: {
    clipPath: "polygon(0% -10%,1% -10%,1% 110%, 0% 110%)",
    opacity: -1,
    zIndex: 0,
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeIn",
    },
  },
  center: {
    clipPath: `polygon(0% -10%,100% -10%,100% 110%, 0% 110%)`,
    zIndex: 0,
    opacity: 1,
    transition: {
      duration: 0.75,
      type: "tween",
      ease: "easeIn",
    },
  },
  exit: {
    clipPath: "polygon(99% -10%,100% -10%,99% 110%,99% 110%)",
    zIndex: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "tween",
      ease: "easeIn",
    },
  },
};

const fadeInOutAnimation = {
  initial: "enter",
  animate: "center",
  exit: "exit",
  variants: fadeInAndOutVariants,
};

const clipPathAnimation = {
  initial: "enter",
  animate: "center",
  exit: "exit",
  variants: clipPath,
};

const slideInOutVariants = {
  enter: {
    x: -500,
    transition: {
      duration: 1,
      type: "tween",
      ease: "easeIn",
    },
  },
  center: {
    x: 0,
    transition: {
      duration: 1,
      type: "tween",
      ease: "easeIn",
    },
  },
  exit: {
    x: 500,
    transition: {
      duration: 1,
      type: "tween",
      ease: "easeIn",
    },
  },
};

const fadeInAndOutWobbleVariants = {
  enter: {
    opacity: 0,
    zIndex: 0,
  },

  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
};

const motionHover = {
  whileHover: { scale: 1.05 },
  whileTap: { scale: 0.95 },
  transition: {
    duration: 0.2,
  },
};

const motionHoverLarge = {
  whileHover: { scale: 1.2 },
  whileTap: { scale: 0.95 },
  transition: {
    duration: 0.2,
  },
};

function motionFadeInObject(viewRef) {
  return {
    animate: viewRef ? "visible" : "hidden",
    variants: fadeInVariants,
    transition: { duration: 0.5, delay: 1, ease: "circIn" },
  };
}

const inViewOptions = {
  threshold: 0.2,
  triggerOnce: false,
};

const fadeScaleIn = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: { delay: 0.2, duration: 0.75 },
};

const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { delay: 0.2, duration: 0.75 },
};

const textAnimation = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      loop: 0,
      type: "spring",
      damping: 16,
      stiffness: 100,
      duration: 1,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      loop: 0,
      type: "spring",
      damping: 16,
      stiffness: 100,
      duration: 2,
    },
  },
};

const textAnimationContainer = {
  initial: "hidden",
  animate: "visible",
  variants: {
    visible: {
      transition: {
        staggerChildren: 0.04,
      },
    },
  },
};

const menuContainer = {
  initial: "enter",
  animate: "center",
  variants: {
    center: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  },
};

const menuListAnimation = {
  enter: {
    opacity: 0,
    y: 250,
    transition: {
      type: "spring",
      damping: 16,
      stiffness: 200,
      duration: 3,
    },
  },
  center: {
    opacity: 1,
    y: 0,
    transition: {
      velocity: -100,
      type: "spring",
      damping: 16,
      stiffness: 200,
      duration: 3,
    },
  },
};

const sideBarVariants = {
  enter: {
    opacity: 0.75,
    zIndex: 0,
    x: "-100%",
  },

  center: {
    zIndex: 100,
    opacity: 1,
    x: 0,
    spring: 200,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 100,
      damping: 40,
      staggerChildren: 0.3,
    },
  },
  exit: {
    zIndex: 0,
    x: "-100%",
    opacity: 0,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 100,
      damping: 40,
    },
  },
};

const sideBarAnimation = {
  initial: "enter",
  animate: "center",
  exit: "exit",
  variants: sideBarVariants,
};

const mapExpand = {
  hidden: {
    zIndex: 0,
    opacity: 0,
    height: "1rem",
    width: "1rem",
    transition: {
      type: "spring",
      damping: 16,
      stiffness: 50,
      duration: 1,
    },
  },
  visible: {
    borderRadius: "10rem",
    background: `${colors.warmPalette.secondary}`,
    border: `8px solid ${colors.warmPalette.contrast}`,
    opacity: 1,
    height: "3rem",
    width: "3rem",
    zIndex: 0,
    transition: {
      type: "spring",
      damping: 16,
      stiffness: 50,
      duration: 0.5,
    },
  },
};

const mapExpandAnimation = {
  initial: "hidden",
  animate: "visible",
  whileHover: {
    zIndex: 1000,
    height: "20rem",
    width: "25rem",
    borderRadius: "0rem",
    border: null,
    background: `${colors.warmPalette.contrast}`,
    transition: {
      duration: 0.5,
    },
  },
  variants: mapExpand,
};

const tripExpand = {
  hidden: {
    zIndex: 0,
    opacity: 0,
    height: "1rem",
    width: "1rem",
    transition: {
      type: "spring",
      damping: 16,
      stiffness: 50,
      duration: 1,
    },
  },
  visible: {
    borderRadius: "10rem",
    background: `${colors.warmPalette.tertiary}`,
    border: `12px solid ${colors.warmPalette.primary}`,
    opacity: 1,
    height: "3.5rem",
    width: "3.5rem",
    zIndex: 0,
    transition: {
      type: "spring",
      damping: 16,
      stiffness: 50,
      duration: 0.5,
    },
  },
};

const tripExpandAnimation = {
  initial: "hidden",
  animate: "visible",
  whileHover: {
    zIndex: 1,
    height: "20rem",
    width: "25rem",
    borderRadius: "0rem",
    border: null,
    background: `${colors.warmPalette.contrast}`,
    transition: {
      duration: 0.5,
    },
  },
  variants: tripExpand,
};

const transitionValues = {
  duration: 1,
  yoyo: Infinity,
  ease: "linear",
};

const bounceTransition = {
  transition: {
    y: transitionValues,
    width: transitionValues,
    height: transitionValues,
  },
  animate: {
    y: ["2rem", "4rem"],
    // width: ['5rem', '5rem', '5rem'],
    // height: ['5rem', '5rem', '5rem'],
  },
};

export {
  motionFadeInObject,
  headerScrollAnimation,
  textAnimationContainer,
  clipPathAnimation,
  fadeInOutAnimation,
  headerTypography,
  fadeIn,
  fadeInVariants,
  fadeScaleIn,
  fadeInAndOutVariants,
  fadeInAndOutWobbleVariants,
  motionHover,
  slideInX,
  inViewOptions,
  cursorDirectionAnimation,
  slideInOutVariants,
  textAnimation,
  logoAnimation,
  menuContainer,
  menuListAnimation,
  sideBarAnimation,
  mapExpand,
  mapExpandAnimation,
  tripExpandAnimation,
  bounceTransition,
  motionHoverLarge,
};
