import React from "react";
import { css } from "styled-components";

// Updated and cleaned code

//////////////////////////////
///        COLOR PALETTES   ///
//////////////////////////////

/**
 * Warm Color Palette
 * Defines primary, secondary, tertiary, neutral light, and contrast colors.
 */
export const warmPalette = {
  primary: "#2E3D56",
  secondary: "#D0BDA8",
  tertiary: "#F1F1E6",
  neutralLight: "#E4DCD3",
  contrast: "#8D5946",
};

/**
 * Cool Color Palette
 * Defines primary, secondary, tertiary, neutral light, and contrast colors.
 * **Note:** Replace the empty strings with actual color values as needed.
 */
export const coolPalette = {
  primary: "", // Example: '#1A1A2E'
  secondary: "", // Example: '#16213E'
  tertiary: "", // Example: '#0F3460'
  neutralLight: "", // Example: '#E94560'
  contrast: "", // Example: '#533483'
};

/**
 * Neutral Color Palette
 * Defines black, dark grey, light grey, and white colors.
 */
export const neutralPalette = {
  black: "#010101",
  greyDark: "#7B7D7D",
  greyLight: "#D0D3D4",
  white: "#FFFFFF",
};

//////////////////////////////
///       COLOR DEFINITIONS ///
//////////////////////////////

/**
 * Colors by Position
 * Defines background and font colors used across the application.
 */
export const colorsByPosition = {
  background: "#FFFFFF", // Background color
  mainFont: "#010101", // Primary font color
  fontWhite: "#FFFFFF", // White font color
  fontBlack: "#010101", // Black font color
};

//////////////////////////////
///        CSS HELPERS      ///
//////////////////////////////

/**
 * Background Color Helper
 * Sets the background color based on the `bg` prop.
 * Supports 'primary', 'secondary', 'tertiary', 'neutral', 'contrast', or defaults to white.
 */
export const bgColor = css`
  background: ${(props) => {
    switch (props.bg) {
      case "primary":
        return warmPalette.primary;
      case "secondary":
        return warmPalette.secondary;
      case "tertiary":
        return warmPalette.tertiary;
      case "neutral":
        return warmPalette.neutralLight;
      case "contrast":
        return warmPalette.contrast;
      default:
        return colorsByPosition.background;
    }
  }};
`;

//////////////////////////////
///          THEME          ///
//////////////////////////////

/**
 * Theme Object
 * Aggregates all color palettes and positional colors for easy access within styled-components.
 */
export const theme = {
  colors: {
    // Warm Palette Colors
    warm: {
      primary: warmPalette.primary,
      secondary: warmPalette.secondary,
      tertiary: warmPalette.tertiary,
      neutralLight: warmPalette.neutralLight,
      contrast: warmPalette.contrast,
    },
    // Cool Palette Colors
    cool: {
      primary: coolPalette.primary,
      secondary: coolPalette.secondary,
      tertiary: coolPalette.tertiary,
      neutralLight: coolPalette.neutralLight,
      contrast: coolPalette.contrast,
    },
    // Neutral Palette Colors
    neutral: {
      black: neutralPalette.black,
      greyDark: neutralPalette.greyDark,
      greyLight: neutralPalette.greyLight,
      white: neutralPalette.white,
    },
    // Colors by Position
    position: {
      background: colorsByPosition.background,
      mainFont: colorsByPosition.mainFont,
      fontWhite: colorsByPosition.fontWhite,
      fontBlack: colorsByPosition.fontBlack,
    },
  },
};
