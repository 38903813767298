import styled from "styled-components";
import { motion } from "framer-motion";
import Image from "next/image";
import * as breakpoints from "../abstracts/_breakpoints";
import * as colors from "../abstracts/_variables.styles";
import * as typo from "../abstracts/_typography.styles";
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const SmallImage = styled(motion.create(Image))`
  width: auto;
  height: 2rem;
  object-fit: cover;
  object-position: right bottom;
  transform: translate(0%, 0%);
  border-radius: 0rem;
  padding: 0rem 0rem;
  ${"" /* margin: auto auto; */}
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    height: 3rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    height: 3rem;
  }
  @media ${extraLargeDevices} {
    height: 3rem;
  }
  @media ${xxLargeDevices} {
    height: 3rem;
  }
`;

export const WhatsAppLogo = styled(motion.create(Image))`
  width: auto;
  height: 5rem;
  object-fit: cover;
  object-position: right bottom;
  transform: translate(0%, 0%);
  border-radius: 0rem;
  padding: 0rem 0rem;
  ${"" /* margin: auto auto; */}
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    height: 5rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    height: 5rem;
  }
  @media ${extraLargeDevices} {
    height: 5rem;
  }
  @media ${xxLargeDevices} {
    height: 5rem;
  }
`;

export const ImgCover = styled(Image)`
  margin: 0rem !important;
  // position: relative !important;
  object-fit: cover;
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${xxSmallDevices} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const Img = styled(motion(Image))`
  object-fit: cover;
  object-position: right middle;
  transform: translate(0%, 0%);
  border-radius: 0%;
  min-width: 10% !important;
  min-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin-left: 0% !important;
  margin-top: 0vh !important;
  position: relative;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
