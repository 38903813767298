import React from "react";
import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
  motion,
} from "framer-motion";
import Script from "next/script";
import { AppProviders } from "../context/index";
import { GlobalStyle } from "../styles/globals.style.js";
import { colors, base, Image } from "../styles/base/_allBase.styles.js";
import logo from "../assets/logo/BB_Logotype-01.png";
const { CenterDiv } = base;

// Fade animation variants as before
const fadeBack = {
  name: "Fade Back",
  variants: {
    initial: {
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        delay: 1,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
        delay: 0,
      },
    },
  },
  transition: {
    duration: 0.1,
  },
};

function MyApp({ Component, pageProps, router }) {
  return (
    (<AppProviders>
      {/* Analytics and Clarity scripts */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-2NKNGZ8YMZ"
        strategy="afterInteractive"
      />
      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'G-2NKNGZ8YMZ');
          gtag('config', 'AW-11381423161');
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
        `}
      </Script>
      <Script id="clarity-init" strategy="afterInteractive">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "lmxygneuzp");
        `}
      </Script>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          background: colors.warmPalette.neutralLight,
        }}
      >
        <CenterDiv>
          {/* Logo */}
          <Image
            alt="Boni Beach Logo"
            src={logo}
            style={{ width: "50vw", height: "auto" }}
          />
        </CenterDiv>
        <LazyMotion features={domAnimation}>
          <AnimatePresence initial={true} mode="wait">
            <motion.div
              key={router.route.concat(fadeBack.name)}
              style={{
                display: "flex",
                position: "absolute",
                height: "100%",
                width: "100vw",
              }}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeBack.variants}
            >
              <Component {...pageProps} />
            </motion.div>
          </AnimatePresence>
        </LazyMotion>
      </div>
      <GlobalStyle />
    </AppProviders>)
  );
}

export default MyApp;
