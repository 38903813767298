import { css } from "styled-components";
import * as colors from "./_variables.styles";

export const h1_xL = "7rem";
export const h1_L = "6.5rem";
export const h1_M = "5rem";
export const h1_xS = "3.5rem";
export const h1_xxS = "3.5rem";

export const h2_xL = "5rem";
export const h2_L = "4.5rem";
export const h2_M = "2rem";
export const h2_xS = "1.6rem";
export const h2_xxS = "1.25rem";

export const h3_xL = "4rem";
export const h3_L = "4rem";
export const h3_M = "3rem";
export const h3_xS = "3rem";
export const h3_xxS = "3rem";

export const h4_xL = "3.5rem";
export const h4_L = "3.5rem";
export const h4_M = "2.75rem";
export const h4_xS = "2.5rem";
export const h4_xxS = "2rem";

export const h5_xL = "2.5rem";
export const h5_L = "2.5rem";
export const h5_M = "2rem";
export const h5_xS = "2rem";
export const h5_xxS = "1.75rem";

export const p_xL = "1.75rem";
export const p_L = "1.5rem";
export const p_M = "1.2rem";
export const p_xS = "1.4rem";
export const p_xxS = "1.2rem";

export const smallP_xL = "1.25rem";
export const smallP_L = "1.5rem";
export const smallP_M = "0.85rem";
export const smallP_xS = "0.75rem";
export const smallP_xxS = "0.85rem";

export const xsmallP_xL = "1rem";
export const xsmallP_L = "1rem";
export const xsmallP_M = "0.65rem";
export const xsmallP_xS = "0.65rem";
export const xsmallP_xxS = "0.5rem";

export const font_color_P_S_T_W_B = css`
  color: ${(props) =>
    props.color === "primary"
      ? colors.warmPalette.primary
      : props.color === "secondary"
        ? colors.warmPalette.secondary
        : props.color === "tertiary"
          ? colors.warmPalette.tertiary
          : props.color === "contrast"
            ? colors.warmPalette.contrast
            : props.color === "white"
              ? colors.font_white
              : colors.font_black};
`;

export const textAlign = css`
  text-align: ${(props) =>
    props.left
      ? "left"
      : props.center
        ? "center"
        : props.right
          ? "right"
          : "left"};
`;
