// @MEDIA BREAKPOINTS
export const xxSmallDevices = `only screen and (min-width: 300px) and (orientation: portrait)`;
export const xxSmallDevicesLandscape = `only screen and (device-height : 375px) and (device-width : 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)`;
export const extraSmallDevices = `only screen and (min-width: 376px) and (orientation: portrait)`;
export const extraSmallDevicesLandscape = `only screen and (max-width: 800px) and (orientation: landscape)`;
export const mediumDevices = `only screen and (min-width: 768px) and (orientation: portrait)`;
export const mediumDevicesLandscape = `only screen and (min-width: 813px) and (orientation: landscape)`;
export const largeDevices = `only screen and (min-width: 1024px)`;
export const extraLargeDevices = `only screen and (min-width: 1200px)`;
export const xxLargeDevices = `only screen and (min-width: 1300px)`;

//////// CSS
// @media ${xxSmallDevices} {}
// @media ${xxSmallDevicesLandscape} {}
// @media ${extraSmallDevices} {}
// @media ${extraSmallDevicesLandscape} {}
// @media ${mediumDevices} {}
// @media ${mediumDevicesLandscape} {}
// @media ${largeDevices} {}
// @media ${extraLargeDevices} {}
// @media ${xxLargeDevices} {}

// // @MEDIA BREAKPOINTS

// /**
//  * Extra Extra Small Devices
//  * - Range: 300px to 375px
//  * - Orientation: Portrait
//  * - Examples: Very small smartphones
//  */
// export const xxSmallDevices = `
//   only screen and (min-width: 300px)
//   and (max-width: 375px)
//   and (orientation: portrait)
// `;

// /**
//  * Extra Extra Small Devices in Landscape
//  * - Range: 376px to 480px
//  * - Orientation: Landscape
//  * - Examples: Small smartphones in landscape
//  */
// export const xxSmallDevicesLandscape = `
//   only screen and (min-width: 376px)
//   and (max-width: 480px)
//   and (orientation: landscape)
// `;

// /**
//  * Extra Small Devices
//  * - Range: 481px to 600px
//  * - Orientation: Portrait
//  * - Examples: Standard smartphones
//  */
// export const extraSmallDevices = `
//   only screen and (min-width: 481px)
//   and (max-width: 600px)
//   and (orientation: portrait)
// `;

// /**
//  * Extra Small Devices in Landscape
//  * - Range: 601px to 768px
//  * - Orientation: Landscape
//  * - Examples: Standard smartphones in landscape
//  */
// export const extraSmallDevicesLandscape = `
//   only screen and (min-width: 601px)
//   and (max-width: 768px)
//   and (orientation: landscape)
// `;

// /**
//  * Medium Devices
//  * - Range: 769px to 1024px
//  * - Orientation: Portrait
//  * - Examples: Small tablets
//  */
// export const mediumDevices = `
//   only screen and (min-width: 769px)
//   and (max-width: 1024px)
//   and (orientation: portrait)
// `;

// /**
//  * Medium Devices in Landscape
//  * - Range: 1025px to 1200px
//  * - Orientation: Landscape
//  * - Examples: Small tablets in landscape and small desktops
//  */
// export const mediumDevicesLandscape = `
//   only screen and (min-width: 1025px)
//   and (max-width: 1200px)
//   and (orientation: landscape)
// `;

// /**
//  * Large Devices
//  * - Range: 1201px to 1600px
//  * - Examples: Desktops and large tablets
//  */
// export const largeDevices = `
//   only screen and (min-width: 1201px)
//   and (max-width: 1600px)
// `;

// /**
//  * Extra Large Devices
//  * - Range: 1601px to 1920px
//  * - Examples: Large desktops
//  */
// export const extraLargeDevices = `
//   only screen and (min-width: 1601px)
//   and (max-width: 1920px)
// `;

// /**
//  * Extra Extra Large Devices
//  * - Range: 1921px and above
//  * - Examples: Ultra-wide desktops and high-resolution monitors
//  */
// export const xxLargeDevices = `
//   only screen and (min-width: 1921px)
// `;
