import { createGlobalStyle } from "styled-components";

/**
 * GlobalStyle Component
 *
 * This component injects global CSS styles into the application.
 * It sets base styles for HTML elements and defines custom fonts.
 */
export const GlobalStyle = createGlobalStyle`
  /* 
    Set the base font size for the HTML element.
    62.5% of the default 16px equals 10px, making rem calculations easier.
  */
  html {
    font-size: 62.5%;
  }

  /* 
    Apply base styles to the body.
    - Set the primary font family.
    - Define a light font weight.
    - Remove default padding and margin.
    - Use border-box for box-sizing to include padding and border in element's total width and height.
  */
  body {
    font-family: 'NimbusSans', sans-serif;
    font-weight: 200;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  /* 
    Remove underline from all anchor tags and set their color to black.
    Ensures consistency across links.
  */
  a {
    text-decoration: none;
    color: black;
  }

  /* 
    Apply box-sizing inheritance to all elements.
    Ensures that all elements use border-box sizing by inheriting from the body.
    Also reinforces the light font weight across all elements.
  */
  *, *::before, *::after {
    box-sizing: inherit;
    font-weight: 200;
  }

  /* 
    Define the 'Editorial New' font family with various font formats for compatibility.
    - font-weight: 200 corresponds to Ultralight.
    - font-display: swap ensures text is displayed immediately using a fallback font until the custom font loads.
  */
  @font-face {
    font-family: 'Editorial New';
    src: url('/fonts/EditorialNew-Ultralight.eot');
    src: url('/fonts/EditorialNew-Ultralight.eot?#iefix') format('embedded-opentype'),
         url('/fonts/EditorialNew-Ultralight.woff2') format('woff2'),
         url('/fonts/EditorialNew-Ultralight.woff') format('woff'),
         url('/fonts/EditorialNew-Ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  /* 
    Define the 'NimbusSans' font family with various font formats for compatibility.
    - font-weight: 300 corresponds to Light.
    - font-display: swap ensures text is displayed immediately using a fallback font until the custom font loads.
  */
  @font-face {
    font-family: 'NimbusSans';
    src: url('/fonts/NimbusSansLight.eot');
    src: url('/fonts/NimbusSansLight.eot?#iefix') format('embedded-opentype'),
         url('/fonts/NimbusSansLight.woff2') format('woff2'),
         url('/fonts/NimbusSansLight.woff') format('woff'),
         url('/fonts/NimbusSansLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  /* 
    Define the 'Whyte-Bold' font family with various font formats for compatibility.
    - font-weight: 400 corresponds to Bold.
    - font-display: swap ensures text is displayed immediately using a fallback font until the custom font loads.
  */
  @font-face {
    font-family: 'Whyte-Bold';
    src: url('/fonts/Whyte-Bold.eot');
    src: url('/fonts/Whyte-Bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Whyte-Bold.woff2') format('woff2'),
         url('/fonts/Whyte-Bold.woff') format('woff'),
         url('/fonts/Whyte-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
`;
